import Vue from 'vue'
import VueRouter from 'vue-router'
import GetStarted from '../views/GetStarted.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/get-started',
    name: 'GetStarted',
    component: GetStarted
  },
  {
    path: '/',
    name: 'main',
    component: () => import( '../views/MainFrame.vue')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import( '../views/MainFrame.vue'),
    children: [
      {
        path: ':company_id',
        name: 'company_item',
        component: () => import( '../views/MainFrame.vue'),

        children: [
          {
            path: 'threatModel/:threat_model_id',
            name: 'threatModel',
            component: () => import( '../views/threatModel.vue')
          },
          {
            path: 'search',
            name: 'Search',
            component: () => import( '../components/search.vue')
          },
          {
            path: ':section_slug',
            name: 'company_section',
            component: () => import( '../views/MainFrame.vue'),

            children: [
              {
                path: ':object_id',
                name: 'company_object',
                component: () => import( '../views/MainFrame.vue'),
              }
            ]
          }
        ]
      }
    ]
  },
  // {
  //   path: '/company/:company_id/threatModel/:threat_model_id',
  //   name: 'threatModel',
  //   component: () => import( '../views/threatModel.vue')
  // },
  {
    path: '/company/:company_id/informationSystem/:is_id/threatModel/:threat_model_id',
    name: 'threatModel_informationSystem',
    component: () => import( '../views/threatModel.vue')
  },
  {
    path: '/maps',
    name: 'maps',
    component: () => import( '../views/MapsFrame.vue'),
    children: [{
      path: ':map_section',
      name: 'map_section',
      component: () => import( '../views/MapsFrame.vue'),
    }]
  },
  {
    path: '/user-list',
    name: 'UserList',
    component: () => import( '../views/UserList.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
