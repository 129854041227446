<template>
    <div class="login_page">
        <div class="login_inner">
            <main_logo v-bind:logo_color="'white'" />
            <p class="text_2xl semibold color_white">Войти в систему</p>
            <v-btn text @click="login()">
                <v-img :src="require('@/assets/yandex_btn.svg')" />
            </v-btn>
            <copyright />
        </div>
    </div>
</template>

<script>
import main_logo from '@/components/logo';
import copyright from '@/components/copyright';

export default {
    name: 'login',
    
    components: {
        main_logo,
        copyright
    },

    methods: {
        login: function()
        {
            // Отправляем запрос в Yandex для получения кода на авторизацию
            location.replace(process.env.VUE_APP_SERVER_ADDRESS + '/oauth2/authorization/yandex');
        }
    }
}
</script>

<style lang="scss">
body .v-application {
    .login_page {
        padding: 2.4em;
        height: 100vh;
        width: 100vw;
        
        .login_inner {
            border-radius: 1.6em;
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-position: center;
            background-size: cover;
            background-image: url('../assets/bgr/login.jpg');
            
            .link {
                margin-top: auto;
                .v-image {
                    width: 82.6em;
                }
            }
            
            .v-btn {
                padding: 0;
                width: 28em;
                font-size: 1em;
                
                margin-top: 2.3em;
                margin-bottom: 0.4em;
            }            
            .copyright {
                margin-top: auto;
                
                p, a {color: $basic_white !important;}
            }
        }
    }
}
</style>
