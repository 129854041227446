<template>
  <v-app>
    <Navigation_bar v-if="$route.name != 'GetStarted' && auth_status" />
    <v-main v-if="auth_status">
      <router-view></router-view>
    </v-main>
    
    <login v-else-if="!auth_status" />
  </v-app>
</template>

<script>
import login from '@/components/login';
import Navigation_bar from '@/components/Navigation_bar';

export default {
  name: 'App',
  components: { Navigation_bar, login },
  
  data: () => ({
    
  }),
  
  created() {
    // Проверяем авторизацию пользователя
    this.$store.dispatch('checkAuth');
    // Получаем список подразделов
    this.$store.dispatch('aux_data');
    // получаем список полей
    this.$store.dispatch('add_dialog_fields');
  },
  
  computed: {
    auth_status() {
      return this.$store.getters.auth_status;
    }
  },
  
  methods: {
    
  },
  
};
</script>

<style lang="scss">
@import './styles/variables.scss';
@import './styles/style.scss';
</style>