<template>
    <div class="get_started">
        <div class="get_started_bgr"></div>
        <div class="get_started_inner">
            <div class="get_started_header">
                <main_logo v-bind:logo_color="'white'" />
                <account_link v-bind:color="'white_text'" />
            </div>
            <div class="text_wrapper">
                <h1 class="text_4xl center color_white">Добро пожаловать!</h1>
                <p class="text_sm center color_white">
                    Выберите существуюущую компанию или создайте новую, чтобы
                    начать работу
                </p>
            </div>
            <div class="card_wrapper shadow_base">
                <template>
                    <v-card
                        class="mx-auto card_item card_item--blue bg_brand_500"
                        @click="f_add_company_diaog()">
                        <PlusCircleIcon />
                        <v-card-title
                            class="text_base color_white semibold center">
                            Добавить компанию
                        </v-card-title>
                        <v-card-text class="text_sm color_white center">
                            Здесь вы можете внести информацию о новой компании в
                            систему
                        </v-card-text>
                    </v-card>
                </template>
                <template>
                    <v-card
                        class="mx-auto card_item card_item--gray bg_neutral_100">
                        <router-link to="/" tag="a" class="link"></router-link>
                        <ArrowCircleRightIcon />
                        <v-card-title class="text_base semibold center">
                            Перейти к списку компаний
                        </v-card-title>
                        <v-card-text class="text_sm center color_neutral_400">
                            Продолжить работу с одной из уже созданных компаний
                        </v-card-text>
                    </v-card>
                </template>
                <template>
                    <v-autocomplete
                        :items="company_list"
                        :filter="customFilter"
                        @focus="f_get_list()"
                        color="white"
                        item-text="name"
                        placeholder="Найти компанию"
                        no-data-text="Ничего не найдено"
                        @change="(event) => f_get_company(event)">
                        <template v-slot:prepend-inner>
                            <SearchIcon />
                        </template>
                    </v-autocomplete>
                </template>
            </div>
        </div>
        <copyright />
    </div>
</template>

<script>
// import axios from 'axios';
import account_link from '@/components/account_header';
import copyright from '@/components/copyright';
import { PlusCircleIcon, ArrowCircleRightIcon, SearchIcon } from "@vue-hero-icons/outline";
import router from '@/router';
import main_logo from '@/components/logo';

export default {
    name: 'GetStarted',

    data: () => ({
        select: null
    }),

    components: {
        main_logo,
        copyright,
        account_link,
        PlusCircleIcon,
        ArrowCircleRightIcon,
        SearchIcon
    },

    computed: {
        company_list() {
            return this.$store.getters.company_list;
        }
    },

    mounted() {

    },

    methods: {
        f_get_company(event)
        {
            var list = this.$store.getters.company_list;
            // Ищем id выбранной компании
            list.forEach(element => {
                if(event == element.name) {
                    // Устанавливаем текущий id
                    this.$store.commit('WRITE_CURRENT_COMPANY_ID', element.id);
                    
                    // Запрашиваем карточку компании
                    this.$store.dispatch('company_card', element.id);

                    // Меняем url
                    this.$router.push({
                        name: "company_item",
                        params: { company_id: element.id },
                    }).catch(()=>{});
                }
            });
        },
        customFilter (item, queryText, itemText) {
            const textOne = item.name.toLowerCase()
            // const textTwo = item.id.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1
        },
        f_add_company_diaog: function()
        {
            // Переход на главную и активация диалога добавления компании
            this.$store.commit('LOAD_COMPONENT', 'add_dialog');
            var add_dialog = { active: true, slug: 'add_company' }
            this.$store.commit('ADD_DIALOG', add_dialog);
            router.push( { path: '/' } )
        },
        f_get_list: function()
        {
            // Получаем список компаний
            this.$store.dispatch('company_list');
        }
    }
}
</script>

<style lang="scss">
.v-main {

    .get_started {
        background: $basic_white;
        padding: 2.4em;
        position: relative;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;

        .get_started_inner {
            position: relative;
            z-index: 100;
            margin-left: auto;
            margin-right: auto;
            width: 91.8em;
        }

        .get_started_bgr {
            width: calc(100% - 4.8em);
            position: absolute;
            top: 2.4em;
            left: 2.4em;
            border-radius: 1.6em;
            overflow: hidden;
            height: 52.1em;
            background-position: center;
            background-size: cover;
            background-image: url('../assets/bgr/get_started.jpg');
        }

        .get_started_header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 1.5em 0;
        }

        .text_wrapper {
            padding-top: 5.5em;
            padding-bottom: 4.2em;

            .text_4xl {
                margin-bottom: .3em;
            }

            p {
                max-width: 21em;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0;
            }
        }

        .card_wrapper {
            padding: 11.2em;
            border-radius: 1.6em;
            background: $basic_white;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 3.2em;

            .card_item {
                box-shadow: none;
                width: 100%;
                border-radius: 1.6em;
                padding: 3.2em 3em 2em 3em;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1em;
                cursor: pointer;

                > a.link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                }

                svg {
                    width: 4em;
                    height: 4em;
                    stroke: $neutral_900;
                }

                &--blue {

                    svg {
                        stroke: $basic_white;
                    }

                    .v-card__text {
                        color: $basic_white;
                        opacity: .5;
                    }
                }
            }

            .v-input {
                grid-column: 1 / span 2;
            }
        }

        .copyright {
            margin-top: auto;

            .text_xs {
                margin-bottom: 0;
            }
        }

    }

}
</style>
