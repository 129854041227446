import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from '@/router';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        auth_status: false,
        status: "",
        currentUser: JSON.parse(localStorage.getItem("currentUser")) || {},
        token: localStorage.getItem("token") || "",
        user: {}, // Данные пользователя
        company_list: null, // Список компаний для сайдбара
        current_company_id: null, // Текущая выбранная компания
        company_card: null, // Карточка компании
        companyWriteFlag: false, // Флаг доступности редактирования компании
        // Ключи зависимых групп чекбоксов в ИТ и ИБ процессах
        keys_it: ['backupMeasuresIds', 'assetMeasuresIds', 'capacityMeasuresIds'],
        keys_infosec: ['physicalSecurityMeasuresIds', 'passwordPoliticsMeasuresIds', 'accessMeasuresIds', 'vulnerabilityManagementMeasuresIds', 'monitoringMeasuresIds'],
        expandable_table_sections: ["documents", "networkSegments", "servers", "processes", "thirdParties", "informationSystems", "networkHardwares", "employees", "physicalSites"],
        flag: false,
        company_scroll_id: null,
        company_search_string_toolbar: null,
        
        company_section: {
            slug: null,
            singular_name: null,
            name: null,
            slug_request: null
        },
        aux_data: null,
        add_dialog_fields: null,
        process_measures_names: {},
        current_component: null,
        add_dialog: {
            active: false,
            slug: null,
            slug_request: null,
            edit_data: null,
            section_type: null,
            item_field: null,
            duplicate_field: null,
            additional_field: null
        },
        thread_model_dialog: {
            active: false,
            confirmed_active: false,
            company_id: null,
            is_id: null,
            thread_model_type: null,
        },
        link_dialog: {
            active: false,
            section: null,
            current_tab: 0,
            item_name: null,
            item_id: null,
            company_id: null,
        },
        link_object: {
            id: null,
            links: {},
            links_ids: {},
            link_for_delete: { id: null, slug: null },
            counter: null,
        },
        map_content: {
            businessProcessData: [],
        },
        map_section: {
            slug: null,
            slug_component: null,
            slug_request: null,
            btn_text: null,
            text: null
        },
        current_softwareData: [],
        // Данные для отправки пост запроса
        post_data: {
            type: null, // map - справочник, company - компания
            data_slug: null, // slug отправляемых данных
            data_item: null, // json для отправки
        },
        put_data: {
            type: null, // map - справочник, company - компания
            data_slug: null, // slug отправляемых данных
            data_item: null, // json для отправки
        },
        delete_data: {
            active: false,
            confirm: false,
            type: null,
            data_section: null,
            data_item_id: null,
        }
    },
    mutations: {
        AUTH_STATUS(state, status) { state.auth_status = status; },
        TOKEN_WRITE(state, token) { state.token = token },
        USER_INFO(state, user) { state.user = user },
        COMPANY_WRITE_FLAG(state, companyWriteFlag) { state.companyWriteFlag = companyWriteFlag },
        WRITE_COMPANY_LIST(state, list) { state.company_list = list.data },
        WRITE_CURRENT_COMPANY_ID(state, id) { state.current_company_id = id },
        WRITE_COMPANY_SCROLL_ID(state, company_scroll_id) { state.company_scroll_id = company_scroll_id },
        WRITE_COMPANY_CARD(state, company_card) { state.company_card = company_card },
        WRITE_COMPANY_SECTION(state, section) {
            state.company_section.slug = section.section_slug;
            state.company_section.singular_name = section.singular_name;
            state.company_section.name = section.section_name;
            state.company_section.slug_request = section.slug_request;
        },
        WRITE_SEARCH_STRING(state, company_search_string_toolbar) { state.company_search_string_toolbar = company_search_string_toolbar; },
        WRITE_AUX_DATA(state, data) { state.aux_data = data; },
        WRITE_ADD_DIALOG_FIELDS(state, data) { state.add_dialog_fields = data.data; },
        LOAD_COMPONENT(state, name) { state.current_component = name; },
        ADD_DIALOG(state, dialog_data) {
            state.add_dialog.active = dialog_data.active;
            state.add_dialog.slug = dialog_data.slug;
            state.add_dialog.slug_request = dialog_data.slug_request;
            state.add_dialog.edit_data = dialog_data.edit_data;
            state.add_dialog.section_type = dialog_data.section_type;
            state.add_dialog.item_field = dialog_data.item_field;
            state.add_dialog.duplicate_field = dialog_data.duplicate_field;
            state.add_dialog.additional_field = dialog_data.additional_field;
        },
        LINK_DIALOG(state, link_dialog) {
            state.link_dialog.active = link_dialog.active;
            state.link_dialog.section = link_dialog.section;
            state.link_dialog.current_tab = link_dialog.current_tab;
            state.link_dialog.item_name = link_dialog.item_name;
            state.link_dialog.item_id = link_dialog.item_id;
            state.link_dialog.company_id = link_dialog.company_id;
        },
        THREAT_MODEL_DIALOG(state, thread_model_dialog) {
            state.thread_model_dialog.active = thread_model_dialog.active;
            state.thread_model_dialog.confirmed_active = thread_model_dialog.confirmed_active;
            state.thread_model_dialog.company_id = thread_model_dialog.company_id;
            state.thread_model_dialog.is_id = thread_model_dialog.is_id;
            state.thread_model_dialog.thread_model_type = thread_model_dialog.thread_model_type;
            // state.link_dialog.item_name = link_dialog.item_name;
            // state.link_dialog.item_id = link_dialog.item_id;
            // state.link_dialog.company_id = link_dialog.company_id;
        },
        WRITE_MAP_SECTION(state, map_section) {
            state.map_section.slug = map_section.slug;
            state.map_section.slug_component = map_section.slug_component;
            state.map_section.slug_request = map_section.slug_request;
            state.map_section.btn_text = map_section.btn_text;
            state.map_section.text = map_section.text;
        },
        WRITE_MAP_CONTENT(state, map_content, slug) {
            if(slug) {
                state.map_content[slug] = map_content[slug];    
            } else {
                state.map_content = map_content;
            }
        },
        WRITE_CURRENT_SOFTWARE_DATA(state, current_softwareData) {
            state.current_softwareData = current_softwareData;
        },
        WRITE_LINK_OBJECT(state, link_object) {
            state.link_object.id = link_object.id;
            state.link_object.links = link_object.links;
            state.link_object.counter = link_object.counter;
            state.link_object.link_for_delete.id = link_object.link_for_delete.id;
            state.link_object.link_for_delete.slug = link_object.link_for_delete.slug;
        },
        DELETE_DATA(state, delete_data) {
            state.delete_data = delete_data;
        }
    },
    actions: {

        // Открытие диалогового окна Добавления
        f_open_add_dialog({ commit, state }, add_dialog) {
            // Подключаем компонент
            commit('LOAD_COMPONENT', 'add_dialog');

            add_dialog = {
                active: true,
                slug: add_dialog.slug,
                slug_request: add_dialog.slug_request,
                edit_data: add_dialog.edit_data,
                section_type: add_dialog.section_type,
                item_field: add_dialog.item_field,
                duplicate_field: add_dialog.duplicate_field,
                additional_field: add_dialog.additional_field
            }
            // Коммитим данные диалога
            commit('ADD_DIALOG', add_dialog);
        },

        f_get_object_links: function ({ commit, state, dispatch }, link_object) {
            var list_total = state.company_card.data;
            var current_slug = state.company_section.slug;
            var current_object = null;
            var fields_list = state.aux_data.data.sectionTableInnerTabs[state.company_section.slug];
            var links = {}

            // Определяем текущий объект
            list_total[current_slug].forEach(item => {
                if (item.id == link_object.id) {
                    current_object = item;
                }
            });

            // Получаем связи текущего объекта
            fields_list.forEach(section_slug => {
                if (current_object != null) {
                    if (section_slug.array_data) {
                        if (current_object[section_slug.slug]) {
                            links[section_slug.slug] = current_object[section_slug.slug];
                        }
                    } else {
                        var slug = section_slug.slug
                        // if (slug == 'physicalSites') { slug == 'physicalSite' }
                        links[section_slug.slug] = current_object[slug]
                    }
                }
            });

            link_object.links = links;

            commit('WRITE_LINK_OBJECT', link_object);
            dispatch('f_object_links_clear');
        },

        // Очищаем связи в объекте от лишних полей
        f_object_links_clear({ commit, dispatch, state }) {
            var links = {};
            var link_object = state.link_object;
            var fields_list = state.aux_data.data.sectionTableInnerTabs[state.company_section.slug];


            fields_list.forEach(section_slug => {
                // console.log(section_slug)
                if (section_slug.array_data) {
                    if (Array.isArray(link_object.links[section_slug.slug])) {
                        links[section_slug.slug] = []
                        link_object.links[section_slug.slug].forEach(link_item => {
                            links[section_slug.slug].push(link_item.id)
                        });
                    }
                } else {
                    if (Array.isArray(link_object.links[section_slug.slug])) {
                        if (link_object.links[section_slug.slug].length > 0) {
                            if (link_object.links[section_slug.slug][0] != null) {
                                links[section_slug.slug] = [link_object.links[section_slug.slug][0].id];
                            }
                        }
                    }
                }
            });

            link_object.links_ids = links;
            commit('WRITE_LINK_OBJECT', link_object);
        },

        // Формирование запроса изменения связей
        f_object_links_request({ commit, dispatch, state }) {
            var link_object = state.link_object;
            var data_item = {};
            var put_data = {};
            var slug_request_mod = null;
            var request_url = null;
            var fields_list = state.aux_data.data.sectionTableInnerTabs[state.company_section.slug];

            data_item.id = link_object.id;

            fields_list.forEach(item => {
                if (item.array_data) {
                    if(link_object.links_ids[item.slug] == undefined) {
                        data_item[item.linked_slug] = [];
                    } else {
                        data_item[item.linked_slug] = link_object.links_ids[item.slug];
                    }
                } else {
                    if (link_object.links_ids[item.slug] != undefined) {
                        data_item[item.linked_slug] = link_object.links_ids[item.slug][0];
                    } else {
                        data_item[item.linked_slug] = 0;
                    }
                }
            });

            put_data = { type: 'company', data_slug: state.company_section.slug_request, data_item: data_item }


            slug_request_mod = state.company_section.slug_request.charAt(0).toUpperCase() + state.company_section.slug_request.slice(1)
            request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/company/' + state.current_company_id + '/edit' + slug_request_mod + 'Links/' + link_object.id;

            // console.log(request_url)
            // console.log(slug_request_mod)
            // console.log(data_item)

            axios
                .put(request_url, data_item)
                .then(function (response) {
                    // В случае успеха закрываем диалог
                    state.link_dialog.active = false;
                    // И запрашиваем новые данные по компании
                    dispatch('company_card', state.current_company_id);
                })
                .catch(err => console.log(err));

        },

        // Открытие диалога удаления
        f_open_delete_dialog({ commit, dispatch }, delete_dialog) {
            var id = [];
            // console.log(delete_dialog)
            // console.log(delete_dialog.company_section.slug_request)
            if (delete_dialog.company_section.slug_request == 'businessProcessData') {
                delete_dialog = {
                    active: false,
                    confirm: false,
                    type: delete_dialog.type,
                    data_section: delete_dialog.company_section,
                    data_item_id: delete_dialog.item
                }
                dispatch('delete_data', delete_dialog);
            } else {
                if (Array.isArray(delete_dialog.item)) {
                    id = [];
                    delete_dialog.item.forEach(element => {
                        id.push(element.id);
                    });
                } else {
                    id.push(delete_dialog.item.id);
                }
                delete_dialog = {
                    active: false,
                    confirm: false,
                    type: delete_dialog.type,
                    data_section: delete_dialog.company_section,
                    data_item_id: id
                }
                dispatch('delete_data', delete_dialog);
            }
        },

        // Диалоговое окно создания связи
        f_open_link_dialog({ commit, state }, link_dialog) {
            link_dialog = {
                active: true,
                section: link_dialog.section,
                current_tab: link_dialog.current_tab,
                item_name: link_dialog.item_name,
                item_id: link_dialog.item_id,
                company_id: link_dialog.company_id
            }

            // Коммитим данные диалога
            commit('LINK_DIALOG', link_dialog);
        },

        logout({ commit }) {
            // выходим из аккаунта
            axios.post(process.env.VUE_APP_SERVER_ADDRESS + '/logout', {}, {
            }).then(function (response) {
                commit("USER_INFO", null);
                localStorage.removeItem("currentUser");
                commit("AUTH_STATUS", false);
                // Сбрасываем возможность редактирования
                commit("COMPANY_WRITE_FLAG", false);
            }).catch(function (err) {
                console.log(err)
            });
        },

        checkAuth({ commit }) {
            // Проверяем авторизацию пользователя
            axios.get(process.env.VUE_APP_SERVER_ADDRESS + '/frontend/user-data')
                .then(function (response) {
                    // console.log(response)
                    // Если авторизован - записываем в хранилище и ставим флаг
                    commit("USER_INFO", response.data.principal);
                    localStorage.setItem("currentUser", JSON.stringify(response.data.principal));
                    commit("AUTH_STATUS", true);
                }).catch(function (error) {
                    console.dir(error);
                    // Для неавторизованного ничего не показываем
                    localStorage.removeItem("currentUser");
                    commit("AUTH_STATUS", false);
                    // Сбрасываем возможность редактирования
                    commit("COMPANY_WRITE_FLAG", false);
                });
        },
        aux_data({ commit, state }) {
            // Возвращает список компаний
            if (state.company_list === null) {
                axios
                    .get('/static/aux_data.json')
                    .then(response => (commit('WRITE_AUX_DATA', response)))
                    .catch(err => console.log(err));
            }
        },
        add_dialog_fields({ commit, state }) {
            // Возвращает список компаний
            if (state.company_list === null) {
                axios
                    .get('/static/add_dialog_fields.json')
                    .then(response => (commit('WRITE_ADD_DIALOG_FIELDS', response)))
                    .catch(err => console.log(err));
            }
        },
        // Собираем объект имен защитных мер у процессов
        f_get_process_measures_name({commit, state}) {
            var names_obj = {};

            var _it = state.add_dialog_fields.processes_ITProcess.fields.dependent_group;
            var _ib = state.add_dialog_fields.processes_InfoSecProcess.fields.dependent_group;

            for (const key in _it) {
                console.log(_it[key])
                if(Array.isArray(_it[key].list)) {
                    _it[key].list.forEach(element => {
                        console.log(element)
                        names_obj[element.id] = element.label
                    });
                }
            }
            for (const key in _ib) {
                if(Array.isArray(_ib[key].list)) {
                    _ib[key].list.forEach(element => {
                        names_obj[element.id] = element.label
                    });
                }
            }

            state.process_measures_names = names_obj;
        },
        company_list({ commit, state }, flag) {
            // Возвращает список компаний
            if (state.company_list === null || flag) {
                axios
                    .get(process.env.VUE_APP_SERVER_ADDRESS + '/frontend')
                    .then(response => (
                        commit('WRITE_COMPANY_LIST', response)
                    ))
                    .catch(err => console.log(err));
            }
        },
        company_card({ state, commit, dispatch }, id, link_id) {
            var section = { section_name: null, section_slug: null, slug_request: null }
            state.flag = false;
            // Возвращает карточку компании
            if (id != null) {
                axios
                    .get(process.env.VUE_APP_SERVER_ADDRESS + '/frontend/company/' + id)
                    .then(function (response) {
                        // Записываем id текущей компании
                        commit('WRITE_CURRENT_COMPANY_ID', id);
                        // Записываем карточку компании
                        commit('WRITE_COMPANY_CARD', response);
                        // Сбрасываем секцию
                        commit("WRITE_COMPANY_SECTION", section);
                        // Проверяем доступность редактирования компании
                        dispatch("check_company_avaliable", id);
                        state.flag = true;
                    })
                    .catch(function (error) {
                        if (error.response) {
                            // Если 401 - ошибка авторизации, отправляем пользователя на страницу авторизации
                            if(error.response.status == 401) {
                                commit("USER_INFO", null);
                                localStorage.removeItem("currentUser");
                                commit("AUTH_STATUS", false);
                                commit("COMPANY_WRITE_FLAG", false);
                            }
                        }
                        console.dir(error);
                    });
            } else {
                // Сбрасываем текущую компанию
                commit('WRITE_CURRENT_COMPANY_ID', null);
                // Сбрасываем карточку компании
                commit('WRITE_COMPANY_CARD', null);
                // Сбрасываем секцию
                commit("WRITE_COMPANY_SECTION", section);
                // Сбрасываем возможность редактирования
                commit("COMPANY_WRITE_FLAG", false);
            }
        },

        // Получаем справочники
        get_map_content({ commit, state }, data) {
            var content = JSON.parse(localStorage.getItem("map_content")) || {};
            var item_data = {};

            // console.log(data.slug, data.flag)

            if (!content || !Object.prototype.hasOwnProperty.call(content, data.slug) || data.flag) {

            axios
                .get(process.env.VUE_APP_SERVER_ADDRESS + '/frontend/' + data.slug)
                .then(function (response) {

                    content[data.slug] = response.data;
                    commit("WRITE_MAP_CONTENT", content)
                    localStorage.setItem("map_content", JSON.stringify(content))
                })
                .catch(function (error) {
                    if (error.response) {
                        // Если 401 - ошибка авторизации, отправляем пользователя на страницу авторизации
                        if(error.response.status == 401) {
                            commit("USER_INFO", null);
                            localStorage.removeItem("currentUser");
                            commit("AUTH_STATUS", false);
                            commit("COMPANY_WRITE_FLAG", false);
                        }
                    }
                    console.dir(error);
                });


            } else {
                item_data[data.slug] = JSON.parse(localStorage.getItem('map_content'))[data.slug]
                commit("WRITE_MAP_CONTENT", item_data, data.slug)
            }
            // console.log(state.map_content)
        },

        // Функция проверки доступа к редактированию компании
        check_company_avaliable({ commit, state }, id) {

            var user = state.user;
            if(user.roles.indexOf('ROLE_ADMIN') != -1 || user.roles.indexOf('ROLE_SUPERVISOR') != -1) {
                // Если права админские даем доступ ко всем компаниям
                commit("COMPANY_WRITE_FLAG", true);
            } else {
                // Если нет - проверяем возможность редактирования у пользователя
                if (state.user.companyWriteIds.includes(parseInt(id))) {
                    commit("COMPANY_WRITE_FLAG", true);
                } else {
                    commit("COMPANY_WRITE_FLAG", false);
                }
            }
        },

        // POST запрос отправки данных
        post_data({ commit, state, dispatch }, post_data) {
            var company_id = null;
            var request_url = null;
            var section_list = state.aux_data.data.sidebar_sections;
            var slug_request = null;
            var slug_request_mod = null;

            // Формирование URL запроса
            if (post_data.type == 'maps') {
                // Справочники

                if (post_data.data_slug == 'employeeRoles') {
                    request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/newEmployeeRole/';
                } else {
                    slug_request_mod = post_data.data_slug.charAt(0).toUpperCase() + post_data.data_slug.slice(1)
                    request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/new' + slug_request_mod + '/';
                }

            } else if (post_data.type == 'add_company') {
                // Компания
                request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/newCompany/';

            } else if (post_data.type == 'company' && (post_data.data_slug == 'processes_BusinessProcess' || post_data.data_slug == 'processes_ITProcess' || post_data.data_slug == 'processes_InfoSecProcess')) {
                // Процессы
                // Получаем id текущей компании
                company_id = state.company_card.data.id;
                // Собираем URL запроса
                request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/company/' + company_id + '/newProcess';
            } else if (post_data.type == 'company') {
                // Подразделы компаний
                // Если запрос компаний выбираем slug для запроса по slug раздела
                section_list.forEach(element => {
                    if (element.slug == post_data.data_slug) {
                        slug_request = element.slug_request
                    }
                });
                // Делаем первую букву большой
                slug_request = slug_request.charAt(0).toUpperCase() + slug_request.slice(1)
                // Получаем id текущей компании
                company_id = state.company_card.data.id;
                // Собираем URL запроса
                request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/company/' + company_id + '/new' + slug_request;
            }



            // Трансформируем данные в зависимых группах чекбоксов в ИТ и ИБ процессах
            if (post_data.data_slug == 'processes_ITProcess') {

                state.keys_it.forEach(key => {
                    var arr = [];
                    post_data.data_item.itProcessDto[key].forEach(element => {
                        arr.push(element.split('item_')[1]);
                    });
                    post_data.data_item.itProcessDto[key] = arr;
                });
            } else if (post_data.data_slug == 'processes_InfoSecProcess') {

                state.keys_infosec.forEach(key => {
                    var arr = [];
                    post_data.data_item.infoSecProcessDto[key].forEach(element => {
                        arr.push(element.split('item_')[1]);
                    });
                    post_data.data_item.infoSecProcessDto[key] = arr;
                });
            }

            console.log(request_url)


            // Отправляем запрос
            axios
                .post(request_url, post_data.data_item)
                .then(function (response) {
                    // В случае успеха закрываем диалог
                    state.add_dialog.active = false;
                    // И запрашиваем новые данные по компании
                    dispatch('company_card', company_id);
                    // Если добавлялась сама компания
                    if (post_data.type == 'add_company') {
                        // запрашиваем список компаний
                        dispatch('company_list', true);
                        // Запрашиваем новые данные пользователя с новым companyWriteIds
                        dispatch('checkAuth');
                        // Открываем текущую компанию
                        dispatch('company_card', response.data.id);
                        // Обновляем id для скролла
                        // commit('WRITE_COMPANY_SCROLL_ID', response.data.id);
                    }
                    // Если работали со справочниками - обновляем
                    if (post_data.type == 'maps') {
                        dispatch('get_map_content', { slug: post_data.data_slug, flag: true })
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        // Если 401 - ошибка авторизации, отправляем пользователя на страницу авторизации
                        if(error.response.status == 401) {
                            commit("USER_INFO", null);
                            localStorage.removeItem("currentUser");
                            commit("AUTH_STATUS", false);
                            commit("COMPANY_WRITE_FLAG", false);
                        }
                    }
                    console.dir(error);
                });
        },


        // PUT запрос отправки измененных данных
        put_data({ commit, state, dispatch }, put_data) {
            var company_id = null;
            var request_url = null;
            var section_list = state.aux_data.data.sidebar_sections;
            var slug_request = null;
            var slug_request_mod = null;

            if (put_data.type == 'maps') {

                if (put_data.data_slug == 'employeeRoles') {
                    request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/editEmployeeRole/' + put_data.data_item.id;
                } else if (put_data.data_slug == 'businessProcessData') {
                    request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/editBusinessProcessData/';
                } else {
                    slug_request_mod = put_data.data_slug.charAt(0).toUpperCase() + put_data.data_slug.slice(1)
                    request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/edit' + slug_request_mod + '/' + put_data.data_item.id;
                }

            } else if (put_data.type == 'add_company') {
                company_id = state.company_card.data.id;
                request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/editCompany/' + put_data.data_item.id;
            } else if (put_data.type == 'user') {
                request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/userList/user/' + put_data.data_item.id;
            } else if (put_data.type == 'company' && (put_data.data_slug == 'processes_BusinessProcess' || put_data.data_slug == 'processes_ITProcess' || put_data.data_slug == 'processes_InfoSecProcess')) {
                // Получаем id текущей компании
                company_id = state.company_card.data.id;
                // Собираем URL запроса
                request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/company/' + company_id + '/editProcess/' + put_data.data_item.id;
            } else if (put_data.type == 'company') {
                // Если запрос компаний выбираем slug для запроса по slug раздела
                section_list.forEach(element => {
                    if (element.slug == put_data.data_slug) {
                        slug_request = element.slug_request;
                    }
                });
                // Делаем первую букву большой
                slug_request = slug_request.charAt(0).toUpperCase() + slug_request.slice(1);
                // Получаем id текущей компании
                company_id = state.company_card.data.id;
                // Собираем URL запроса
                request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/company/' + company_id + '/edit' + slug_request + '/' + put_data.data_item.id;
            }


            // Трансформируем данные в зависимых группах чекбоксов в ИТ и ИБ процессах
            if (put_data.data_slug == 'processes_ITProcess') {
                state.keys_it.forEach(key => {
                    var arr = [];
                    put_data.data_item.itProcessDto[key].forEach(element => {
                        arr.push(element.split('item_')[1]);
                    });
                    put_data.data_item.itProcessDto[key] = arr;
                });
            } else if (put_data.data_slug == 'processes_InfoSecProcess') {
                state.keys_infosec.forEach(key => {
                    var arr = [];
                    put_data.data_item.infoSecProcessDto[key].forEach(element => {
                        arr.push(element.split('item_')[1]);
                    });
                    put_data.data_item.infoSecProcessDto[key] = arr;
                });
            }


            // console.log(put_data)


            // Отправляем запрос
            axios
                .put(request_url, put_data.data_item)
                .then(function (response) {
                    // В случае успеха закрываем диалог
                    state.add_dialog.active = false;
                    // И запрашиваем новые данные по компании
                    dispatch('company_card', company_id);
                    // Если менялась сама компания - запрашиваем список компаний
                    if (put_data.type == 'add_company') {
                        dispatch('company_list', true);
                    }
                    // Если работали со справочниками - обновляем
                    if (put_data.type == 'maps') {
                        dispatch('get_map_content', { slug: put_data.data_slug, flag: true })
                    }
                    // Если удаление пункта данных бизнес процесса
                    if (put_data.data_slug == 'businessProcessData') {
                        state.delete_data.active = false;
                        state.delete_data.confirm = false;
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        // Если 401 - ошибка авторизации, отправляем пользователя на страницу авторизации
                        if(error.response.status == 401) {
                            commit("USER_INFO", null);
                            localStorage.removeItem("currentUser");
                            commit("AUTH_STATUS", false);
                            commit("COMPANY_WRITE_FLAG", false);
                        }
                    }
                    console.dir(error);
                });
        },


        // DELETE запрос на удаление данных
        delete_data({ commit, state, dispatch }, delete_data) {
            // console.log(delete_data)
            var request_url = null;
            var company_id = null;
            var slug_request = null;
            var section_list = state.aux_data.data.sidebar_sections;
            var url_array = [];

            console.log(delete_data)

            if (!delete_data.confirm) {
                // Открываем окно
                delete_data.active = true;
                commit("DELETE_DATA", delete_data);
            } else {

                // Формируем запрос на удаление
                if (delete_data.type == 'maps') {

                    if (delete_data.data_section.slug_request == 'employeeRoles') {
                        request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/deleteEmployeeRole/' + delete_data.data_item_id[0];
                        url_array.push(request_url)
                    } else {
                        slug_request = delete_data.data_section.slug_request;
                        var slug_request_mod = slug_request.charAt(0).toUpperCase() + slug_request.slice(1);

                        delete_data.data_item_id.forEach(element => {
                            request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/delete' + slug_request_mod + '/' + element;
                            url_array.push(request_url);
                        });
                    }


                } else if (delete_data.type == 'company_item') {

                    company_id = state.company_card.data.id;
                    request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/deleteCompany/' + company_id;

                    delete_data.data_item_id.forEach(element => {
                        request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/deleteCompany/' + element;
                        url_array.push(request_url);
                    });

                } else if (delete_data.type == 'company') {

                    // Если запрос компаний выбираем slug для запроса по slug раздела
                    section_list.forEach(element => {
                        if (element.slug == delete_data.data_section.slug) {
                            slug_request = element.slug_request
                        }
                    });
                    // Делаем первую букву большой
                    slug_request = slug_request.charAt(0).toUpperCase() + slug_request.slice(1)
                    // Получаем id текущей компании
                    company_id = state.company_card.data.id;
                    // Собираем URL запроса

                    delete_data.data_item_id.forEach(element => {
                        request_url = process.env.VUE_APP_SERVER_ADDRESS + '/frontend/company/' + company_id + '/delete' + slug_request + '/' + element;
                        url_array.push(request_url);
                    });

                }

                console.log(delete_data)
                console.log(url_array)

                // Отправляем запрос

                url_array.forEach((url, index) => {
                    // Обновляем индекс итерации
                    index++;
                    axios
                        .delete(url)
                        .then(function (response) {
                            // В случае успеха закрываем диалог
                            state.delete_data.active = false;
                            state.delete_data.confirm = false;

                            // Если выполняется последний запрос - выполняем функции обновления данных
                            if (index == url_array.length) {
                                // Если удалена компания
                                if (delete_data.type == 'company_item') {
                                    // Получаем новый список
                                    dispatch('company_list', true);
                                    // Сбрасываем текущую компанию
                                    commit('WRITE_CURRENT_COMPANY_ID', null);
                                    // Сбрасываем карточку компании
                                    commit('WRITE_COMPANY_CARD', null);
                                    // Отправляемся на главный экран
                                    router.push({ name: 'main' })
                                } else if (delete_data.type == 'company') {
                                    // И запрашиваем новые данные по компании
                                    dispatch('company_card', company_id);
                                } else
                                    if (delete_data.type == 'maps') {
                                        // Если работали со справочниками - обновляем
                                        dispatch('get_map_content', { slug: delete_data.data_section.slug_request, flag: true })
                                    }
                            }
                        })
                        .catch(function (error) {
                        if (error.response) {
                            // Если 401 - ошибка авторизации, отправляем пользователя на страницу авторизации
                            if(error.response.status == 401) {
                                commit("USER_INFO", null);
                                localStorage.removeItem("currentUser");
                                commit("AUTH_STATUS", false);
                                commit("COMPANY_WRITE_FLAG", false);
                            }
                        }
                        console.dir(error);
                    });

                });
            }
        }


    },

    getters: {
        // Статус авторизации
        auth_status(state) { return state.auth_status },
        token(state) { return state.token },
        user(state) { return state.user },
        companyWriteFlag(state) { return state.companyWriteFlag },
        company_list(state) { return state.company_list },
        current_company_id(state) { return state.current_company_id },
        company_scroll_id(state) { return state.company_scroll_id },
        company_card(state) { return state.company_card },
        company_section(state) { return state.company_section },
        aux_data(state) { return state.aux_data },
        add_dialog_fields(state) { return state.add_dialog_fields },
        process_measures_names(state) { return state.process_measures_names },
        current_component(state) { return state.current_component },
        // Поисковая строка компании из шапки
        company_search_string_toolbar(state) { return state.company_search_string_toolbar },
        // Диалог добавление/редактирования сущностей
        add_dialog(state) { return state.add_dialog },
        // Диалог модели угроз
        thread_model_dialog(state) { return state.thread_model_dialog },
        // Диалог создания связей
        link_dialog(state) { return state.link_dialog },
        delete_data(state) { return state.delete_data },
        // Текущий раздел справочника
        map_section(state) { return state.map_section },
        // Содержимое справочников
        map_content(state) { return state.map_content },
        // Отдельный список программ для добавления сервера
        softwareData(state) { return state.map_content.softwareData },
        // Отдельный список данных бизнесс процесса
        businessProcessData(state) { return state.map_content.businessProcessData },
        // Отдельный список ролей пользователя
        employeeRoles(state) { return state.map_content.employeeRoles },
        // Отдельный список защитных мер
        securityControlsData(state) { return state.map_content.securityControlsData },
        // Список выбранных установленных ПО для сервера
        current_softwareData(state) { return state.current_softwareData },
        // Отдельный список угроз
        damageData(state) { return state.map_content.damageData },
        // Отдельный список нарушителей
        intruderData(state) { return state.map_content.intruderData },
        keys_it(state) { return state.keys_it },
        keys_infosec(state) { return state.keys_infosec },
        // Связи объекта
        link_object(state) { return state.link_object },
        flag(state) { return state.flag },

    },
});
