<template>
    <div class="copyright">
        <p class="text_xs color_neutral_400">
            @ 2023, Made by <a href="#" target="_blank" class="color_brand_500 semibold">Card Security</a> & <a href="https://rednosed.agency/" target="_blank" class="color_brand_500 semibold">RND</a>
        </p>
    </div>
</template>
  
<script>
export default {
    name: 'copyright',
}
</script>


<style lang="scss">
.copyright {
    margin-bottom: 2.6em;
    p {
        margin-bottom: 0;
    }
}
</style>
  