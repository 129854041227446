<template>
    <nav>
        <v-navigation-drawer permanent app width="30em" v-if="auth_status">
            <main_logo v-bind:logo_color="'color'" />
            <v-tabs v-model="tab">
                <v-tab value="one" :key="1">
                    <router-link to="/" tag="a" class="link">
                        Компании
                    </router-link>
                </v-tab>
                <v-tab value="two" :key="2">
                    <router-link to="/maps" tag="a" class="link">
                        Справочники
                    </router-link>
                </v-tab>
                <v-tab-item :key="1">
                    <!-- Поиск компаний -->
                    <template>
                        <v-text-field
                            placeholder="Найти компанию"
                            v-model="company_search_string"
                            clearable
                            class="input_small">
                            <template v-slot:prepend-inner>
                                <SearchIcon />
                            </template>
                        </v-text-field>
                    </template>
                    <!-- Список компаний -->
                    <template>
                        <v-row
                            justify="start"
                            v-if="company_list"
                            style="margin: 0;">
                            <v-expansion-panels
                                accordion
                                v-model="panel"
                                v-if="company_list">
                                <v-expansion-panel
                                    v-for="(item, i) in company_list"
                                    :id="'company_' + item.id"
                                    :key="i">
                                    <!-- Название компании -->
                                    <v-expansion-panel-header
                                        @click="f_get_company_card(item)">
                                        <UserCircleIcon />
                                        <span>{{ item.name }}</span>
                                    </v-expansion-panel-header>
                                    <!-- Список подразделов -->
                                    <v-expansion-panel-content>
                                        <v-list>
                                            <v-list-item
                                                v-for="(item_sub, i) in aux_data.data.sidebar_sections"
                                                :class="f_check_active_class(item.id,item_sub.slug)"
                                                :ripple="false"
                                                :key="i"
                                                @click="f_get_company_section_info( item.id, item_sub.slug, item.slug_request )">
                                                <!-- Подраздел -->
                                                <v-list-item-title>
                                                    {{ item_sub.name }}
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-row>
                        <div v-else>
                            <p
                                class="text_sm semibold"
                                style="width: 100%; text-align: center; ">
                                Компаний не найдено
                            </p>
                        </div>
                    </template>
                </v-tab-item>
                <v-tab-item :key="2">
                    <!-- Список разделов справочника -->
                    <v-list class="maps_list">
                        <v-list-item>
                            <v-list-item-title
                                @click="f_get_map_section(map_item)"
                                :class="f_check_active_map_section(map_item.slug)"
                                :ripple="false"
                                v-for="map_item in maps_list"
                                :key="map_item.slug">
                                <BookOpenIcon />
                                <span>
                                    {{ map_item.text }}
                                </span>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-tab-item>
            </v-tabs>
            <!-- Доп меню в сайдбаре -->
            <v-list>
                <v-list-item v-if="f_check_admin()">
                    <v-list-item-title
                        @click="f_sumbenu_routing('UserList')"
                        :ripple="false"
                        :class="{ 'active': submenu_class == 'UserList' }">
                        <UserGroupIcon />
                        <span>Пользователи</span>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
            {{ company_scroll_id }}
            <copyright />
        </v-navigation-drawer>
        <!-- Верхний бар -->
        <v-app-bar app flat height="68" v-if="auth_status">
            <!-- Хлебные крошки -->
            <v-breadcrumbs :items="breadcrumbs_list">
                <template v-slot:divider>
                    <ChevronRightIcon />
                </template>
            </v-breadcrumbs>
            <div
                class="catWrapper catWrapper--toolbar"
                v-if="current_company_id != null">
                <!-- Поиск чего-то -->
                <template>
                    <v-text-field
                        v-model="header_search_string"
                        placeholder="Поиск"
                        clearable
                        class="input_small">
                        <template v-slot:prepend-inner>
                            <SearchIcon />
                        </template>
                    </v-text-field>
                </template>

                <v-btn
                    @click="f_go_to_search()"
                    class="btn btn--primary btn--small">
                    <span class="text_sm">Искать</span>
                </v-btn>
            </div>
            <account_link />
        </v-app-bar>
        <!-- Верхний бар -->
        <v-app-bar app flat height="68" v-if="!auth_status">
            <account_link />
        </v-app-bar>
    </nav>
</template>

<!-- <UserGroupIcon /> -->

<script>
import { ref } from 'vue';
import axios from 'axios';
import main_logo from "@/components/logo";
import copyright from "@/components/copyright";
import account_link from "@/components/account_header";
import { SearchIcon, ChevronDownIcon, ChevronRightIcon } from "@vue-hero-icons/outline";
import { UserCircleIcon, BookOpenIcon, UserGroupIcon } from "@vue-hero-icons/solid";

export default {
    name: "Navigation_bar",
    components: { main_logo, copyright, account_link, SearchIcon, BookOpenIcon, UserCircleIcon, ChevronRightIcon, UserGroupIcon },

    data: () => ({
        tab: null,
        panel: null,
        auth: false,
        company_search_string: null,
        header_search_string: null,
        sections: [],
        list: [],
        submenu_class: null,
        company_list_filter: [],

        maps_list: [
        {
            slug: "businessProcessDataMap",
            slug_component: "map_type_fields_list",
            slug_request: "businessProcessData",
            btn_text: "Добавить данные",
            text: "Данные бизнес-процесса"
        },
        {
            slug: "softwareDataMap",
            slug_component: "map_type_table",
            slug_request: "softwareData",
            btn_text: "Добавить новое ПО",
            text: "Программное обеспечение"
        },
        {
            slug: "intruderDataMap",
            slug_component: "map_type_table",
            slug_request: "intruderData",
            btn_text: "Добавить нарушителя",
            text: "Нарушители"
        },
        {
            slug: "employeeRoles",
            slug_component: "map_type_fields_list",
            slug_request: "employeeRoles",
            btn_text: "Добавить роль",
            text: "Роли сотрудников"
        },
        {
            slug: "damageDataMap",
            slug_component: "map_type_table",
            slug_request: "damageData",
            btn_text: "Добавить ущерб",
            text: "Ущерб"
        },
        {
            slug: "threatDataMap",
            slug_component: "map_type_table_threatData",
            slug_request: "threatData",
            btn_text: "Добавить угрозу",
            text: "Угрозы"
        },
        {
            slug: "securityControlsDataMap",
            slug_component: "map_type_table",
            slug_request: "securityControlsData",
            btn_text: "Добавить защитную меру",
            text: "Защитные меры"
        },
        {
            slug: "weightDataMap",
            slug_component: "map_type_table_weight",
            slug_request: "weightData",
            btn_text: "Загрузить из CSV",
            text: "Веса"
        },
        ],

        breadcrumbs_list: [],

        categoryList: ["Категория 1", "Категория 2", "Категория 3"],
    }),
    computed: {
        auth_status() {
            return this.$store.getters.auth_status;
        },
        company_list() {
            // Поиск в массиве компаний
            var list = this.$store.getters.company_list;
            if(this.company_search_string) {
                return list.filter(item => {
                    this.panel = null
                    // this.$store.commit('WRITE_CURRENT_COMPANY_ID', null)
                    return item.name.toLowerCase().includes(this.company_search_string.toLowerCase())
                })
            } else {
                return list;
            }
        },
        current_company_id() {
            return this.$store.getters.current_company_id;
        },
        aux_data() {
            return this.$store.getters.aux_data;
        },
        company_card() {
            return this.$store.getters.company_card;
        },
        company_section() {
            return this.$store.getters.company_section;
        },
        map_section() {
            return this.$store.getters.map_section;
        },
        company_scroll_id() {
            return this.$store.getters.company_scroll_id;
        },
        user() {
            return this.$store.getters.user;
        },
        company_search_string_toolbar() {
            return this.$store.getters.company_search_string_toolbar;
        },

    },
    watch: {
        // При переключении табов сбрасываем текущие подразделы
        tab: function()
        {
            var map_section = { slug: null, slug_component: null, slug_request: null, btn_text: null, text: null };
            var company_section = { slug: null, singular_name: null, name: null, slug_request: null };

            this.$store.commit('WRITE_COMPANY_SECTION', company_section);
            this.$store.commit('WRITE_MAP_SECTION', map_section);
            this.$store.commit('WRITE_CURRENT_COMPANY_ID', null);
            this.f_open_current_company_list(null)
        },
        current_company_id: function (to, from) {
            this.f_breadcrumbs();
            this.f_open_current_company_list(to);
        },
        company_card: function() {
            this.f_breadcrumbs()
        },
        'company_section.slug': function () {
            setTimeout(() => {
                if(!this.$route.params.object_id) {
                    this.f_get_company_section_info(this.$store.getters.current_company_id, this.$store.getters.company_section.slug, this.$store.getters.company_section.slug_request)
                }
            }, 100);
            this.f_breadcrumbs();
        },
        $route (to, from) {
            // this.f_breadcrumbs();
        },
        company_scroll_id: function(to, from) {
            if(to != null) {
                this.scrollToElement(to)
            }
        },
        '$route.name': function(to, from)
        {
            if(typeof this.$route.query.q != 'string') {
                this.header_search_string = null
                this.$store.commit('WRITE_SEARCH_STRING', null)
            }
            this.f_breadcrumbs();
        },
        company_list: function(to) 
        {
            this.company_list_filter = to;
            this.f_open_current_company_list(this.$store.getters.current_company_id)
        }
    },
    created() {
    },

    mounted() {

        // Получаем список компаний
        this.$store.dispatch('company_list');

        // Открываем активную вкладку
        if(this.$store.getters.company_list && this.$store.getters.current_company_id) {
            this.f_open_current_company_list(this.$store.getters.current_company_id);
        }

        if(this.$store.getters.company_list) {
            this.company_list_filter = this.$store.getters.company_list
        }

        // Обновляем хлебные крошки

        setTimeout(() => {

            if(this.$route.params.company_id != null) {
                this.$store.commit('WRITE_CURRENT_COMPANY_ID', this.$route.params.company_id);
            }

            // Проверяем раздел
            this.f_checkMaps();

            if(this.$route.name == 'UserList') {
                this.f_sumbenu_routing(this.$route.name, true)
            }

            // Записываем поисковую строку из URL
            if(this.$route.name == 'Search') {
                this.header_search_string = decodeURI(this.$route.query.q);
                this.f_go_to_search();
            }

            this.f_breadcrumbs();
        }, 300);

    },

    methods: {
        scrollToElement: function(to)
        {
            setTimeout(() => {
                const targetId = ref('company_' + to)
                const el = document.getElementById(targetId.value);

                if (el) {
                    el.scrollIntoView();
                    this.$store.commit('WRITE_COMPANY_SCROLL_ID', null)
                }
            }, 1000);
        },
        // Проверка админских прав
        f_check_admin: function()
        {
            var user = this.$store.getters.user;
            if(user.roles.indexOf('ROLE_ADMIN_off') != -1 || user.roles.indexOf('ROLE_SUPERVISOR_off') != -1) {
                return true;
            } else {
                return false;
            }
        },
        f_sumbenu_routing: function(name, flag)
        {
            if(this.$route.name != name) {
                this.f_open_current_company_list(null);
                this.$store.commit('WRITE_CURRENT_COMPANY_ID', null);
                this.submenu_class = name;
                this.$router.push({ name: name });
                // console.log(this.submenu_class)
            }
            if (flag) {
                this.submenu_class = name
            }
            // console.log(this.$route.name)
        },
        // Проверяем таб в сайдбаре
        f_checkMaps: function()
        {
            var self = this;
            if(this.$route.name == 'maps' || this.$route.name == 'map_section') {
                this.tab = 1;

                if(this.$route.name == 'map_section') {
                    setTimeout(() => {
                        var item = this.$route.params.map_section;
                        this.maps_list.forEach(element => {
                            if(element.slug == item) {
                                self.f_get_map_section(element)
                            }
                        });
                        self.f_breadcrumbs()
                    }, 100);
                }
            } else {
                this.tab = 0;
            }
        },
        // Выбираем подраздел справочника
        f_get_map_section: function(map_item)
        {

            // console.log(map_item)
            var map_section = { slug: map_item.slug, slug_request: map_item.slug_request, slug_component: map_item.slug_component, text: map_item.text, btn_text: map_item.btn_text }
            this.$store.commit("WRITE_MAP_SECTION", map_section)

            // Меняем url
            this.$router.push({
                name: "map_section",
                params: { map_section: map_item.slug },
            }).catch(()=>{});
        },
        // При изменении URL обновляем хлебные крошки
        f_breadcrumbs: function() {           

            var list = [];
            var main_item = { text: null, disabled: true, href: null };
            var company_item = { text: null, disabled: true, href: null };
            var section_item = { text: null, disabled: true, href: null };
            var object_item = { text: null, disabled: true, href: null };
            var search = { text: null, disabled: true, href: null };
            var maps_page = { text: null, disabled: true, href: null };
            var map_section = { text: null, disabled: true, href: null };
            var user_list = { text: null, disabled: true, href: null };
            var company, section = null;
            var route = this.$route;
            var company_card = this.$store.getters.company_card

            main_item = { text: "Главная", disabled: true, href: "/" }


            list.push(main_item);


            if(company_card) {
                // Компания
                if(route.name == 'company_item') {
                    main_item.disabled = false;
                    company_item = { text: company_card.data.name, disabled: true, href: "/company/" + company_card.data.id }
                }
                // Подраздел
                if(route.name == 'company_section') {
                    main_item.disabled = false;
                    company_item = { text: company_card.data.name, disabled: false, href: "/company/" + company_card.data.id }
                    section = this.$store.getters.company_section;
                    section_item = { text: section.name, disabled: true, href: "/company/"+ company_card.data.id +"/" + section.slug }
                }
                // Объект
                if(route.name == 'company_object') {
                    main_item.disabled = false;
                    company_item = { text: company_card.data.name, disabled: false, href: "/company/" + company_card.data.id }
                    section = this.$store.getters.company_section;
                    section_item = { text: section.name, disabled: true, href: "/company/"+ company_card.data.id +"/" + section.slug }
                }

            }

            // Поиск
            if(route.name == 'Search') {
                main_item.disabled = false;
                search = { text: 'Результаты поиска', disabled: true, href: "/search/" }
            }


            if(this.$store.getters.map_section) {
                // Справочники
                if(route.name == 'maps') {
                    main_item.disabled = false;
                    maps_page = { text: 'Справочники', disabled: true, href: "/maps/" }
                }
                if(route.name == 'map_section') {
                    main_item.disabled = false;
                    maps_page = { text: 'Справочники', disabled: false, href: "/maps/" }
                    map_section = { text: this.$store.getters.map_section.text, disabled: true, href: "/maps/"+ this.$store.getters.map_section.slug }
                }

            }

            // Список пользователей
            if(route.name == 'UserList') {
                main_item.disabled = false;
                user_list = { text: 'Пользователи', disabled: true, href: "/user-list/" }
            }


            setTimeout(() => {
                this.breadcrumbs_list = [];

                if(route.name == 'company_item') { list.push(company_item); }
                if(route.name == 'company_section') { 
                    list.push(company_item); list.push(section_item);
                }
                if(route.name == 'company_object') { 
                    list.push(company_item); list.push(section_item);
                }
                if(route.name == 'Search') { list.push(search); }

                if(route.name == 'maps') { list.push(maps_page); }
                if(route.name == 'map_section') { list.push(maps_page); list.push(map_section) }
                if(route.name == 'UserList') { list.push(user_list); }

                this.breadcrumbs_list = list;
                
            }, 100);

        },
        // Отправляем запрос на получение инфо о компании
        f_get_company_card: function (item)
        {
            this.submenu_class = null;

            if(this.$store.getters.current_company_id == item.id) {
                // Запрашиваем карточку компании
                this.$store.dispatch('company_card', null);

                this.f_check_active_class(null, null);

                setTimeout(() => {
                    this.panel = null;
                }, 200);

                // Меняем url
                this.$router.push({
                    name: "main"
                }).catch(()=>{});
            } else {
                // Запрашиваем карточку компании
                this.$store.dispatch('company_card', item.id);

                // Меняем url
                this.$router.push({
                    name: "company_item",
                    params: { company_id: item.id },
                    hash: '',
                }).catch(()=>{});
            }
        },
        // Отправялем запрос на получение информации о подразделе
        f_get_company_section_info: function ( company_id, section_slug, slug_request )
        {

            if(this.$store.getters.current_company_id != null) {

                this.company_id = company_id

                var section_title = null;
                var section_singular_name = null;
                this.submenu_class = null;

                var sidebar_sections = this.$store.getters.aux_data.data.sidebar_sections;

                if(section_slug != undefined) {

                    for (const [key] in sidebar_sections) {
                        if (Object.hasOwnProperty.call(sidebar_sections, key)) {
                            const element = sidebar_sections[key];
                            if(element.slug == section_slug) {
                                section_title = element.name
                                section_singular_name = element.singular_name
                                slug_request = element.slug_request
                            }
                        }
                    }

                    var section = { section_slug: section_slug, section_name: section_title, singular_name: section_singular_name, slug_request: slug_request }

                    this.$store.commit("WRITE_COMPANY_SECTION", section)

                    // Меняем url
                    this.$router.push({
                        name: "company_section",
                        params: { company_id: this.company_id, section_slug: section_slug }
                    }).catch(()=>{});

                }
            }


        },
        f_check_active_class: function (company_id, section_slug) {
            if ( company_id == this.$store.getters.current_company_id && section_slug == this.$store.getters.company_section.slug ) {
                return "active";
            } else if (company_id == null && section_slug == null) {
                return '';
            }
        },

        f_check_active_map_section: function(slug)
        {
            if(this.$store.getters.map_section.slug == slug) {
                return "active";
            }
        },

        // Отправляемся на строку поиска
        f_go_to_search: function()
        {
            if(this.header_search_string != null) {
                var search = encodeURI(this.header_search_string);

                // Меняем url
                this.$router.push({
                    name: "Search",
                    params: { company_id: this.$store.getters.current_company_id },
                    query: {
                        q: search
                    }
                }).catch(()=>{});

                this.$store.commit('WRITE_SEARCH_STRING', this.header_search_string)

            }
        },

        f_open_current_company_list: function (id) {
            // Проверяем полученный id и открываем соотв вкладку
            var comp_list = this.company_list_filter;
            let cnt = 0;
            if(id != null) {
                if(Array.isArray(comp_list)) {
                    comp_list.forEach((company_item) => {
                        if (id == company_item.id) {
                            this.panel = cnt;
                        }
                        cnt++;
                    });
                }
            } else if (id == null) {
                this.panel = null;
            }
        }
    },
};
</script>
