<template>
    <div class="account_link" :class="{ white_text: color }">
        <div class="account_item" v-if="auth_status">
            <!-- Popover с ролями пользователя -->
            <v-menu
                v-if="user.roles.length > 1"
                v-model="roles_flag"
                bottom
                origin="center center"
                transition="slide-y-transition"
                :close-on-content-click="false"
                max-width="220"
                min-width="200"
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <v-avatar
                            class="avatar"
                            size="32"
                            v-if="user.is_avatar_empty">
                            <span
                                class="text_sm uppercase color_neutral_400 semibold">
                                ИФ
                            </span>
                        </v-avatar>
                        <v-avatar
                            class="avatar"
                            size="32"
                            v-if="!user.is_avatar_empty">
                            <img
                                :src="'https://avatars.yandex.net/get-yapic/'+ user.attributes.default_avatar_id +'/islands-retina-50'"
                                alt="" />
                        </v-avatar>

                        <div class="user_roles">
                            <p class="name text_sm semibold" :class="color == 'white_text' ? 'color_white':'color_neutral_600'">
                                {{ user.fullName }}
                            </p>
                            <span class="text_sm roles_counter" :class="color == 'white_text' ? 'color_white':'color_brand_500'">
                                {{ user.roles.length }} <span v-html="f_roles_text(user.roles.length)"></span>
                            </span>
                        </div>
                    </div>
                </template>

                <v-card flat class="roles_popover">
                    <p class="text_sm">
                        <span v-for="(role, index) in user.roles" :key="role">
                            {{ roles_names[role] }}<span v-if="index + 1 != roles.length">,</span>
                        </span>
                    </p>
                </v-card>
            </v-menu>

            <div v-else>
                <v-avatar class="avatar" size="32" v-if="user.is_avatar_empty">
                    <span class="text_sm uppercase color_neutral_400 semibold">
                        ИФ
                    </span>
                </v-avatar>
                <v-avatar class="avatar" size="32" v-if="!user.is_avatar_empty">
                    <img
                        :src="'https://avatars.yandex.net/get-yapic/'+ user.attributes.default_avatar_id +'/islands-retina-50'"
                        alt="" />
                </v-avatar>

                <div class="user_roles">
                    <p class="name text_sm semibold color_neutral_600" :class="color == 'white_text' ? 'color_white':'color_neutral_600'">
                        {{ user.fullName }}
                    </p>
                    <span class="text_sm" :class="color == 'white_text' ? 'color_white':'color_brand_500'">
                        {{ roles_names[user.roles[0]] }}
                    </span>
                </div>
            </div>
        </div>
        <div class="logout" v-if="auth_status" @click="logout">
            <LogoutIcon />
            <span class="text_sm semibold">Выход</span>
        </div>
        <div class="login" v-if="!auth_status" @click="login">
            <LoginIcon />
            <span class="text_sm semibold">Войти с Yandex ID</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { LogoutIcon, LoginIcon } from "@vue-hero-icons/solid"

export default {
    name: 'account_link',
    props: ['color'],
    user: {},

    data: () => ({
        roles_flag: false,
        roles_names: {
            ROLE_AUDITOR: 'Аудитор',
            ROLE_SALES: 'Продажи',
            ROLE_SUPERVISOR: 'Супервизор',
            ROLE_ADMIN: 'Администратор',
        },
        roles: ["ROLE_AUDITOR", "ROLE_SALES", "ROLE_SUPERVISOR", "ROLE_ADMIN"],
    }),

    components: {
        LogoutIcon,
        LoginIcon
    },

    computed: {
        auth_status() {
            return this.$store.getters.auth_status;
        },
        token() {
            return this.$store.getters.token;
        },
        user() {
            return this.$store.getters.user;
        }
    },

    mounted() {
    },

    methods: {
        isNumber: function (n)
        {
            return !isNaN(parseFloat(n)) && !isNaN(n - 0)
        },
        login: function()
        {
            // Отправляем запрос в Yandex для получения кода на авторизацию
            location.replace(process.env.VUE_APP_SERVER_ADDRESS + '/oauth2/authorization/yandex');
        },
        logout: function()
        {
            this.$store.dispatch('logout')
        },
        checkToken: function()
        {
            var token = localStorage.getItem('token');
            if(token) {
                this.$store.dispatch('user_info');
            }
        },
        // Склонение ролей
        f_roles_text: function(count)
        {

            if(count > 1 && count < 5) {
                return 'роли';
            } else {
                return 'ролей';
            }
        }
    }
}
</script>

<style lang="scss">
.account_link,
.logout,
.login {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;

    p {
        margin-bottom: 0 !important;
    }
}

.account_link.white_text {
    .account_item {
        p.name {
            color: $basic_white;
        }
    }

    .logout,
    .login {
        span {
            color: $basic_white;
        }
        svg {
            fill: $basic_white;
        }
    }
}

.roles_popover {
    background: $neutral_700 !important;
    color: $basic_white !important;
    box-shadow: none !important;
    padding: 0.5em 1em;
    max-width: 220px;
}
.account_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5em;

    &:hover {
        .user_roles .roles_counter {
            color: $brand_700;
        }
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5em;
    }

    .avatar {
        order: 1;
    }
    .user_roles {
        order: 2;

        .roles_counter {
            transition: color .3s;
            color: $brand_600;
        }

        .text_sm {
            line-height: 1.4 !important;
        }
    }

}

.avatar {
    width: 3.2em;
    height: 3.2em;
    position: relative;
    border-radius: 50%;
    background: $neutral_200;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.logout,
.login {
    cursor: pointer;
    gap: 1em;

    span {
        color: $neutral_600;
    }

    svg {
        width: 2em;
        height: 2em;
        fill: $neutral_600;
    }
}
</style>
