<template>
    <div @click="f_go_main()" class="link" style="cursor: pointer;">
        <v-img :src="require('../assets/main_logo_color.svg')" v-if="logo_color == 'color'"/>
        <v-img :src="require('../assets/main_logo_white.svg')" v-if="logo_color == 'white'"/>
    </div>
</template>
  
<script>

export default {
    name: 'main_logo',
    props: [ 'logo_color' ],

    methods: {
        f_go_main: function()
        {
            var section = { section_slug: null, section_name: null, singular_name: null, slug_request: null };
            this.$store.commit('WRITE_CURRENT_COMPANY_ID', null);
            this.$store.commit("WRITE_COMPANY_SECTION", section);

            this.$router.push({
                name: "GetStarted"
            }).catch(()=>{});
        }
    }
}
</script>
  